import { Link } from "react-router-dom"
import file from "../static/images/JamesLotruglio_Resume.pdf"


export const Navbar = () =>{
  // const pdfFile = "https://www.jimlotruglio.com/static/media/JamesLotruglio_Resume.a5f45259a174c135f3b1.pdf"

    return (
        <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
  <div className="container-fluid">
    <h3 className="navbar-brand" >Jim Lotruglio</h3>
    {/* <Link className="navbar-brand" to={"/"}>Navbar</Link> */}
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarColor01">
      <ul className="navbar-nav me-auto">
        <li className="nav-item">
          <Link className="nav-link active" to={"/"}>Home
            <span className="visually-hidden">(current)</span>
          </Link>
        </li>
        {/* <div><a href={file} rel="noreferrer" target="_blank"> Download Resume</a></div> */}
        <li className="nav-item">
        <a className="nav-link" href={file} rel="noreferrer" target="_blank">Resume</a>
          {/* <Link className="nav-link" to={"/resume"}>Resume</Link> */}
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={"/about"}>About Me</Link>
        </li>
        <li className="nav-item">
          {/* <a className="nav-link" href="#">About</a> */}
        </li>
        <li className="nav-item dropdown">
          {/* <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a> */}
          <div className="dropdown-menu">
            {/* <a className="dropdown-item" href="#">Action</a> */}
            {/* <a className="dropdown-item" href="#">Another action</a> */}
            {/* <a className="dropdown-item" href="#">Something else here</a> */}
            <div className="dropdown-divider"></div>
            {/* <a className="dropdown-item" href="#">Separated link</a> */}
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
    )
}