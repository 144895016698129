import '../static/style/bootstrap.css'
import { Navbar } from '../components/Navbar'

const Layout = (props) => (
    <div className="bg-info-subtle main-style"  >
        <Navbar /> 
        <div className="content-style card">
            {props.children}
        </div>
        
    </div>
)

export default Layout