import './App.css';
import { Route, Routes } from 'react-router-dom';
import Layout from './hocs/Layout';
import Home from './containers/Home';
import Resume from './containers/Resume';
import AboutMe from './containers/AboutMe';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/resume' element={<Resume />} />
        <Route path='/about' element={<AboutMe />} />
      </Routes>
    </Layout>
  );
}

export default App;
