import { useEffect, useState } from "react"
// import file from "../static/images/JamesLotruglio_Resume.pdf"

const Resume = () => {
    const [pdfFile, setPdfFile] = useState("")
    // const pdfFile = "http://localhost:3000/static/media/JamesLotruglio_Resume.a5f45259a174c135f3b1.pdf#zoom=100"
    // const pdfFile = "https://www.jimlotruglio.com/static/media/JamesLotruglio_Resume.a5f45259a174c135f3b1.pdf"

    useEffect(() => {
        setPdfFile("https://www.jimlotruglio.com/static/media/JamesLotruglio_Resume.a5f45259a174c135f3b1.pdf")
    },[])

    const handlePDFReady = () => {
        console.log(pdfFile);
        if(pdfFile !== ""){
            return (<iframe
                        title="ResumeJamesLotruglio"
                        src={pdfFile}
                        height="700px"
                        width="80%"
                    ></iframe> )
        }
    }


    return (
        <div className="text-center">
            <h1>Resume Page</h1>
            {handlePDFReady()}
            {/* <object data={pdfFile} type="application/pdf" height="700px" width="80%" > */}
            {/* <div><a href={file} rel="noreferrer" target="_blank"> Download Resume</a></div> */}
            {/* <iframe
                 title="ResumeJamesLotruglio"
                 src={pdfFile}
                 height="700px"
                 width="80%"
            ></iframe>  */}
            {/* </object> */}
        </div>
    )
}

export default Resume