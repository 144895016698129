

const Home = () =>{


    return (
        <div>
            <h1>Under Construction</h1>
            {/* <a href="https://wordlyapp.netlify.app/" rel="noreferrer" target="_blank">Wordly</a> */}
        </div>
    )
}

export default Home