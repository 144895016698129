

const AboutMe = () => {


    return (
        <div>
            <h1>About Me</h1>
        </div>
    )
}

export default AboutMe